export default {
  homepage: {
    'what-do-you-want-to-eat': '¿Qué quieres comer?',
    'choose-delivery-type-let-discover':
      'Elige el método de envío y descubramos todos los productos que están a un clic de distancia.'
  },
  products: {
    unavailable: 'Agotado'
  },
  axios: {
    403: 'Usted no tiene acceso a esta sección. Trate de cerrar la sesión y volver en.',
    524: 'El comando se ha ejecutado. Por favor revise esta y si no intentarlo de nuevo.',
    'has-unkown-error': 'Procesamiento de datos de error.',
    'no-internet': 'Por favor, compruebe su conexión a internet.',
    'unknown-error': 'Póngase en contacto con nosotros.'
  },
  'something-went-wrong': 'Algo salió mal.',
  product: {
    description: 'Descripción',
    allergens: 'Alérgenos',
    'nutritional-facts': 'Información nutricional',
    ingredients: 'Ingredientes'
  },
  'payment-methods': {
    cash: 'Pago en efectivo',
    'cash-pickup': 'Pago en efectivo al retirar',
    pos: 'Pago en efectivo al retirar',
    'pos-pickup': 'Pago con tarjeta en el TPV (al recoger)',
    card: 'Pago con tarjeta en línea',
    paypal: 'Paga en línea con PayPal',
    'pay-with': 'Pagar con'
  },
  paymentStatus: {
    succeeded: '¡Pago realizado con éxito!',
    processing: 'Procesando pago. Lo actualizaremos cuando se reciba el pago.',
    'requires-payment-method': 'Pago fallido. Intente con otro método de pago.',
    'error-checking': 'No se pudo verificar el pago. Verifique su cuenta bancaria si el dinero ha sido retirado.'
  },
  validation: {
    'insert-full-name': 'Por favor ingresa tu nombre completo.'
  },
  delivery: 'Entrega',
  pickup: 'Recoger',
  categories: 'Categorías',
  'your-last-order': 'Tu último pedido',
  'recommended-products': 'Productos Recomendados',
  'all-products': 'Todos los productos',
  'search-product': 'Buscar producto...',
  'no-search-result': '0 resultados para: {search}',
  cookies: {
    welcome: 'Bienvenida!',
    'info-message': 'Queremos darte una experiencia personalizada',
    'info-message-2': 'Este sitio utiliza cookies para brindarle una experiencia personalizada.',
    settings: 'Configuración de cookies',
    'i-agree': 'Estoy de acuerdo',
    'settings-dialog': {
      title: 'Este sitio utiliza cookies',
      info: 'Utilizamos cookies que son necesarias para que la plataforma funcione.<br>También nos gustaría establecer cookies de análisis opcionales para poder optimizar la funcionalidad del sitio y analizar y mejorar el producto.<br>No estableceremos cookies de análisis a menos que las habilite. Para obtener más información sobre las cookies que utilizamos, consulte <a href="#" class="text-positive">Política de cookies</a>.'
    },
    needed: 'Necesaria',
    performance: 'Actuación',
    statistically: 'Estadísticas',
    marketing: 'Marketing'
  },
  contact: {
    title: 'Contacto'
  },
  reservations: {
    title: 'Reservas',
    'over-phone': 'Para reservas telefónicas',
    'send-success': 'El formulario ha sido enviado con éxito',
    'you-will-be-contacted': 'Será contactado por un representante en el menor tiempo posible.'
  },
  account: {
    title: 'Mi cuenta',
    details: 'Detalles de la cuenta',
    benefits: 'Beneficios',
    'delete-account': 'Eliminar su cuenta',
    'no-longer-access-to-account': 'Ya no tendrás acceso a tus beneficios y ofertas especiales.',
    'are-you-sure-delete-account': '¿Estás segura de que quieres eliminar esta cuenta?',
    'delete-account-lose-points':
      'Tú perderás <b>{points} {currency}</b> en puntos de bonificación, y <b>ya no podrás utilizar los vales</b>.',
    'delete-account-reason': 'La razón por la que desea eliminar su cuenta',
    'delete-delivery-address': 'Eliminar la dirección de entrega',
    'are-you-sure-delete-delivery-address': '¿Está seguro de que desea eliminar la dirección? {name}?',
    'delete-credit-card': 'Eliminar la tarjeta',
    'are-you-sure-delete-credit-card': '¿Estás segura de que quieres eliminar la tarjeta?: {last4}?',
    'add-new-card': 'Puede agregar una nueva tarjeta, solo cuando realiza un nuevo pedido.',
    orders: 'Pedidos',
    profile: 'Perfil',
    logout: 'Cerrar sesión',
    'current-orders': 'Órdenes actuales',
    'past-orders': 'Historial de pedidos',
    'update-profile': 'Actualización del perfil',
    'change-password': 'Cambia la contraseña',
    'bonus-points': 'Puntos extra',
    loyalty: {
      'win-extra-points': 'Gana puntos extra',
      'explain-order-percent': 'Obtienes un {percent}% de lo que pagas.',
      'explain-order-delivery-percent': 'Recibes un {percent}% del valor de los pedidos con entrega a domicilio.',
      'explain-order-pickup-percent': 'Recibirá un {percent}% del valor de los pedidos para recoger en su ubicación.',
      'explain-order-table-percent': 'Recibirá un {percent}% del valor de sus pedidos de mesa.',
      'recommend-to-friend': 'Recomendar a un amigo',
      'referral-explain': 'Recibe <b>{points} puntos</b> en el primer pedido de un amigo.'
    }
  },
  menu: 'Menú',
  'about-us': {
    title: 'Sobre nosotras'
  },
  events: {
    title: 'Eventos',
    'free-entrance': 'Entrada libre',
    'current-events': 'Eventos actuales',
    'past-events': 'Eventos pasados',
    'see-location': 'Ver ubicación',
    'send-reservation': 'Enviar una reserva para el evento',
    'no-events': 'Actualmente no hay eventos',
    'follow-us': 'Síganos en una de las redes sociales para saber cuándo organizamos el próximo evento.'
  },
  legal: {
    title: 'Legal',
    'terms-and-conditions': 'Términos y condiciones',
    'cookie-policy': 'Política de cookies',
    'privacy-policy': 'Política de privacidad'
  },
  filters: {
    'select-allergens': 'Seleccionar alérgenos a evitar',
    'info-allergens': 'Todas las categorías también incluyen productos derivados',
    allergens: {
      1: 'Gluten',
      2: 'Productos lácteos',
      3: 'Mariscos',
      4: 'Huevos',
      5: 'Pez',
      6: 'Miseria',
      7: 'Haba de soja',
      8: 'Nueces',
      9: 'Apio',
      10: 'Mostaza',
      11: 'Semillas de sésamo',
      12: 'Dióxido de azufre',
      13: 'Lupina',
      14: 'Moluscos'
    },
    reset: 'Restablecer filtros'
  },
  geolocation: {
    'choose-other-pickup': 'Elige otra dirección de recogida',
    'choose-other-delivery': 'Elige otra dirección de entrega',
    'see-location-details': 'Ver detalles de ubicaciones',
    'warning-delivery': 'Tenga en cuenta que algunos productos y descuentos no están disponibles para la entrega.',
    'street-number': 'Número de calle',
    'apartment-floor': 'Piso, apartamento, intercomunicador',
    'location-name': 'Nombre de la ubicación, por ejemplo: Casa (optional)',
    'special-informations': 'Instrucciones especiales (optional)',
    'no-address': 'Debe introducir una dirección.',
    'leave-email-notify-delivery-area':
      'Déjanos tu dirección de correo electrónico y te avisaremos cuando realizaremos la entrega a esta dirección.',
    'no-delivery-to-this-location': 'Lamentablemente no hacemos envíos a esta dirección.',
    'no-delivery-to-this-location-has-pickup': 'Lamentablemente no hacemos envíos a esta dirección.',
    'address-saved-success': 'Dirección guardada con éxito.',
    'location-saved-success': 'Ubicación guardada con éxito.',
    'add-address': 'Agregar nueva dirección',
    'edit-address': 'Editar dirección',
    'save-address': 'Guardar la dirección',
    'search-for-your-address': 'Encuentra tu direccion',
    'select-address-on-the-map': 'Localiza tu dirección en el mapa',
    'no-street-number': 'La dirección seleccionada no contiene el número de la calle.'
  },
  'order-product': {
    'special-instructions': 'Instrucciones especiales',
    'instructions-extra-charge': 'Algunas modificaciones podrían tener un cargo extra',
    'add-product-to-order': 'Agregar a la orden',
    maxim: 'Máxima',
    options: 'opciones',
    'product-added-success': 'El producto fue agregado exitosamente.',
    'product-delete-success': 'El producto ha sido eliminado con éxito.',
    'max-options': 'El número máximo de opciones que se pueden seleccionar es {options}.',
    'max-quantity': 'La cantidad máxima que se puede pedir es {quantity}.',
    'min-options-notification': 'El número mínimo de opciones que se pueden seleccionar para {name} es {options}.',
    'min-quantity-notification': 'La cantidad mínima que se puede pedir para {name} es {quantity}.',
    'choose-option': 'Elige la opción',
    'already-added-order-now':
      'Tienes productos en tu cesta para entrega hoy. El pedido con ent rega hoy no se puede combinar con un pedido con entrega en una fecha programada.'
  },
  cart: {
    'your-cart-is-empty': 'Tu carrito esta vacío',
    'no-products': 'Usted actualmente no tiene productos en su carro de compras',
    'please-add-product': 'Agregue productos al carrito para poder realizar un pedido.',
    'what-do-you-want-to-eat': '¿Qué quieres comer?',
    'my-order': 'Mi pedido',
    'delete-product': 'Eliminar producto',
    'are-you-sure-remove-product':
      '¿Estás seguro de que quieres eliminar el producto? <b>{productName}</b> from the order?',
    'loyalty-at-checkout':
      'La aplicación de <b>puntos de bonificación y vales</b> se realiza en el paso final del pedido.',
    'loyalty-validation-error': 'El número de puntos ingresados es incorrecto.',
    'delivery-tax': 'Gastos de envío',
    'packaging-tax': 'Tarifa de embalaje',
    total: 'Total',
    'minimum-order-is': 'El pedido mínimo es de {value}. El envío no está incluido.',
    checkout: {
      'finish-order': 'Completar el pedido',
      'do-you-want-cutlery': '¿Quieres cubiertos?',
      'when-to-receive-order': '¿Cuándo quieres recibir el pedido?',
      'have-a-promotional-code': '¿Tienes un código de promoción?',
      'only-one-voucher-per-order': 'Los vales no se pueden combinar. Solo puede utilizar un vale por pedido.',
      'loyalty-explained':
        'Nº máximo de puntos/pedido es de {points}.<br/>Los puntos de bonificación se acumulan después de realizar un pedido.<br/>1 punto = 1 {currency}, conoce más detalles en el <a href="/account/benefits">Beneficios </a> sección de tu perfil.',
      'save-card': 'Guarde la tarjeta en su cuenta para futuros pedidos',
      'use-other-card': 'Usa otra tarjeta'
    }
  },
  'order-success': {
    title: 'Gracias por su pedido.',
    body: 'Los productos le llegarán en el menor tiempo posible.',
    'body-pickup': 'Los productos comenzarán a prepararse lo antes posible.',
    'wait-time-delivery': 'Su pedido llegará en <span style="white-space:nowrap">~{minutes} minutos</span>.',
    'wait-time-pickup': 'Su pedido estará listo en <span style="white-space:nowrap">~{minutes} minutos</span>.',
    'later-body-delivery': 'Tu pedido será entregado en tu domicilio a las {time}.',
    'later-body-pickup': 'Tu pedido podrá ser recogido a las {time} en la unidad {store_name}.',
    'see-order-status-on-orders-page':
      'Siga el estado de su pedido en la sección <a href="/account/orders">Pedidos</a>',
    'order-brought-to-you':
      'Este pedido le traerá <span class="color-green"><b>{points} puntos extra</b></span>, puedes conocer más detalles en la sección de  <a href="/account/benefits">Beneficios</a>',
    'order-will-bring-you':
      'Este pedido te gana <span class="color-green"><b>{points} puntos extra</b></span>, que puedes convertir en descuentos en tu próximo pedido, <b>1 point = 1 {currency}</b>.'
  },
  common: {
    'email-saved-success': 'La dirección de correo electrónico se guardó correctamente.',
    'delivery-method': 'Método de entrega',
    'delivery-address': 'Dirección de entrega',
    'pickup-address': 'Dirección de entrega',
    'payment-method': 'Método de pago',
    'contact-informations': 'Información del contacto',
    yes: 'Sí',
    no: 'No',
    order: 'Orden',
    'my-order': 'Mi pedido',
    'continue-with': 'Continua con',
    or: 'o',
    'link-copied': 'El enlace se copió con éxito.',
    'link-couldnt-be-copied': 'No se pudo copiar el enlace.',
    'payment-status': 'Estado de pago'
  },
  loyalty: {
    discounts: 'Descuentos',
    'this-order-brings-you':
      'Este pedido gana <span class="color-green"><b>{points} puntos extra</b></span> que puedes convertir en descuentos en tu próximo pedido, <b>1 puntos = 1 {currency}</b>.',
    'create-account-for-loyalty':
      'Cree una cuenta para beneficiarse de puntos de bonificación, vales y ofertas especiales.',
    'available-bonus-points': 'Puntos de bonificación disponibles',
    'congrats-your-total-points': '<b>Felicidades!</b> El total de puntos de bonificación es:',
    vouchers: 'Vales',
    'available-vouchers': 'Cupones disponibles',
    'no-available-vouchers': 'No tienes vales disponibles.',
    'vouchers-info':
      'Los vales no se pueden combinar. Solo puede utilizar un vale por pedido.<br/>Los vales no se pueden transferir a otras usuarios.<br/>Puede recibir cupones después de suscribirse al boletín o promociones especiales.<br/>Los vales tienen un período de validez.',
    'bonus-points-info':
      'Los puntos de bonificación se acumulan después de realizar un pedido.<br/><b>1 puntos = 1 {currency}</b>, puede usar puntos de bonificación para obtener descuentos en pedidos.<br/El número máximo de puntos de bonificación que puede usar por pedido es 50.<br/>Los puntos de bonificación no caducan.<br/>Los puntos de bonificación se pueden usar junto con otros beneficios (e.g. vales, promociones)',
    'no-bonus-points': 'Actualmente no tienes puntos de bonificación'
  },
  buttons: {
    confirm: 'Confirmar',
    continue: 'Continuar',
    save: 'Ahorrar',
    'save-address': 'Guardar la dirección',
    send: 'Enviar',
    'place-order': 'Hacer el pedido',
    cancel: 'Cancelar',
    'continue-shopping': 'Seguir comprando',
    validate: 'Validar',
    use: 'usar',
    close: 'Cerca',
    'pay-with-cc': 'Paga en línea con tu tarjeta',
    'change-payment': 'Cambiar método de pago',
    menu: 'Menú',
    pay: 'Pagar',
    send: 'Enviar',
    'copy-link': 'Copiar link',
    'change-payment': 'Cambiar método de pago'
  },
  fields: {
    name: 'Nombre',
    'your-name': 'Tu nombre',
    'email-address': 'Dirección de correo electrónico',
    email: 'Correo electrónico',
    phone: 'Teléfono',
    password: 'Contraseña',
    date: 'Fecha',
    number: 'Número',
    total: 'Total',
    'pickup-by-car': 'Recoger en Auto',
    'pickup-counter': 'Recoger en Mostrador',
    'pickup-now': 'Recogida en {minutes} minutos',
    'pickup-later': 'Recogida mas tarde',
    'delivery-now': 'Entregar ahora ({minutes} min.)',
    'delivery-later': 'Entregar más tarde',
    size: 'Talla',
    voucher: 'Coupon',
    'old-password': 'Contraseña anterior',
    'new-password': 'Nueva contraseña',
    points: 'punto',
    day: 'Día',
    hour: 'Houe',
    'bonus-points': 'Puntos extra',
    'your-message': 'Tu mensaje',
    'no-of-persons': 'El número de personas',
    event: 'Evento'
  },

  'no-products': 'No hay productos en esta categoría.',
  'shop-closed': 'No se realizan entregas en este momento.',
  'privacy-policy': 'Política de privacidad',
  'cookie-policy': 'Política de cookies',
  'terms-and-conditions': 'Términos y condiciones',
  order: 'Su pedido',
  'order-now': 'Order now',
  'finish-order': 'Completar el pedido',
  'view-your-cart': 'Ver el carrito',
  'send-order': 'Enviar pedido',
  'total-order': 'Total de la orden',
  quantity: 'Cantidad',
  'special-instructions': 'Instrucciones especiales',
  'instructions-extra-charge': 'Algunas modificaciones podrían tener cargo extra',
  'add-product-to-order': 'Agregar al pedido',
  maxim: 'Máximo',
  options: 'Opciones',
  'product-added-success': 'El producto fue añadido con éxito.',
  'max-options': 'El número máximo de opciones que pueden ser seleccionadas es {options}.',
  'max-quantity': 'La cantidad máxima que se puede pedir es {quantity}.',
  'min-options-notification': 'El número mínimo de opciones que se deben seleccionar para {name} es {options}.',
  'min-quantity-notification': 'La cantidad mínima que se puede pedir para {name} es {quantity}.',
  'powered-by': 'Powered by ',
  'product-name': 'Nombre del producto',
  'unit-price': 'Precio por unidad',
  'total-price': 'Precio Total',
  'your-name': 'Su nombre',
  'your-phone': 'Su teléfono',
  address: 'Dirección',
  'i-agree-with': 'Estoy de acuerdo con',
  tos: 'los términos y condiciones de uso',
  'continue-accept-tos': 'Al continuar, acepta los términos y condiciones de uso.',
  'auto-accept-promotion': 'Acepto recibir promociones personalizadas.',
  'view-cart': 'Ver el carrito de compras',
  'payment-cash': 'Pagar a la entrega',
  'payment-pos': 'Puedo pagar con la tarjeta en la entrega',
  'payment-cash-pickup': 'Pagar al recoger',
  'payment-pos-pickup': 'Puedo pagar con la tarjeta en la recoger',
  'payment-card': 'Pagar ahora con tarjeta de crédito',
  'payment-paypal': 'Pagar ahora con PayPal',
  'min-order-value': 'El pedido mínimo es de {value}.',
  'voucher-code': 'Código de cupón',
  'validate-voucher': 'Validar',
  subtotal: 'Subtotal',
  total: 'Total',
  voucher: 'Coupon',
  'delivery-address': 'Dirección de entrega',
  'street-name-number': 'Nombre de la calle y número',
  'enterence-floor-apartment': 'El bloque, de entrada, apartamento',
  'save-address': 'Guardar dirección',
  'delete-product': 'Eliminar el producto',
  'are-you-sure-remove-product': 'Estás seguro de que desea quitar <b>{product}</b> de la orden?',
  cancel: 'Cancelar',
  'product-delete-success': 'El producto fue eliminado con éxito.',
  'i-want-contactless-delivery': 'Quiero entrega sin contacto.',
  'i-want-cutlery': 'Quiero cubiertos',
  'i-accept-promotion': 'Sí, me gustaría recibir promociones especiales',
  'search-product': 'Búsqueda de productos ...',
  'no-search-result': '0 resultados para: {search}',
  'address-saved-success': 'La dirección se ha guardado correctamente.',
  'address-must-from-list': 'Debes seleccionar la dirección de la lista',
  'marker-out-of-range': 'Moviste el cursor demasiado lejos del escrito de la dirección.',
  'choose-address-hint':
    'Ingrese el nombre de la calle, seguido directamente por el número de dirección, por ejemplo: Main Street 21',
  'change-address': 'Cambio de dirección',
  'set-address': 'Establezca la dirección de entrega',
  'set-street-number': 'Establecer el nombre de la calle y número',
  transport: 'Transporte',
  'free-transport-after': 'Entrega gratuita después de',
  'delivery-time': 'El tiempo de entrega',
  'close-dialog': 'Cerrar la ventana',
  'share-product': 'Compartir producto',
  'copy-link': 'Copia',
  'copy-link-success': 'El enlace se ha copiado correctamente.',
  'copy-link-error': 'El enlace no se puede copiar. Por favor selecciónelo y cópielo manualmente.',
  'pay-order': 'Pagar el pedido',
  'pay-now': 'Tengo que pagar de forma segura',
  'go-homepage': 'Ir a la página principal',
  change: 'Cambio',
  gdpr: {
    'this-web-use-cookies': 'Este sitio web utiliza cookies',
    text: 'Este sitio web almacena los datos, tales como las cookies para habilitar importante la funcionalidad del sitio, incluyendo análisis, segmentación y personalización. Para saber más, puedes leer nuestro <a href="/page/cookie-policy" style="color:#fff">política de Privacidad aquí</a>.',
    'i-understand': 'Entiendo'
  },
  'page-order-success': {
    title: 'Gracias por su pedido.',
    body: 'Los productos le llegarán en el menor tiempo posible.',
    'body-pickup': 'Los productos comenzarán a prepararse lo antes posible.',
    'wait-time-delivery': 'Su pedido llegará en <span style="white-space:nowrap">~{minutes} minutos</span>.',
    'wait-time-pickup': 'Su pedido estará listo en <span style="white-space:nowrap">~{minutes} minutos</span>.',
    'later-body-delivery': 'Tu pedido será entregado en tu domicilio a las {time}.',
    'later-body-pickup': 'Tu pedido podrá ser recogido a las {time} en la unidad {store_name}.',
    'see-order-status-on-orders-page':
      'Siga el estado de su pedido en el sección <a href="/account/orders">Pedidos</a>',
    'order-brought-to-you':
      'Este pedido te ganó <span class="color-green"><b>{points} puntos extra</b></span>, puedes conocer más detalles en el sección <a href="/account/benefits">Beneficios</a>',
    'order-will-bring-you':
      'Este pedido te gana <span class="color-green"><b>{points} puntos extra</b></span>, que puedes convertir en descuentos en tu próximo pedido, <b>1 punto = 1 {currency}</b>.'
  },
  'gmap-autocompelte-no-results': 'No hay resultados.',
  'delivery-type': {
    delivery: 'Entrega a Domicilio',
    pickup: 'Recoger'
  },
  'set-pickup': 'Elige el lugar',
  'change-pickup': 'Cambiar ubicación',
  'pickup-saved-success': 'La ubicación se guardó correctamente',
  auth: {
    'order-without-account': 'Pedido sin cuenta',
    'enter-account': 'Iniciar sesión en la cuenta',
    'login-for-ordering': 'Para realizar un pedido, elija uno de los métodos de autenticación.',
    'reset-password': 'Restablecer la contraseña',
    'reset-password-info': 'Por favor, introduzca su dirección de correo electrónico para restablecer su contraseña',
    'new-password': 'Establecer nueva contraseña',
    'forgot-password': '¿Has olvidado la contraseña?',
    'validate-phone-number': 'Validar el número de teléfono',
    'validate-phone-enter-code': 'Ingrese el código recibido por SMS!',
    'validate-phone-number-resend-text': 'Reenviar el SMS',
    'login-with-google-unavailable': 'La autenticación de Google no está disponible en este momento.',
    'order-wihout-account': 'Pedido sin cuenta',
    'place-an-order': 'Haz un pedido',
    'terms-and-conditions': 'Términos y condiciones',
    'accept-promotion': 'Acepto recibir promociones personalizadas.',
    'i-accept-promotion': 'Quiero recibir ofertas personalizadas.',
    'i-agree-with': 'estoy de acuerdo con',
    tos: 'Términos y condiciones de uso',
    logout: 'Cerrar sesión',
    'logout-success': 'Ha cerrado correctamente la sesión de su cuenta.',
    'are-you-sure-logout': '¿Está seguro de que desea cerrar la sesión??',
    register: 'Crea una cuenta',
    'register-btn': 'Crear una cuenta',
    login: '¡Bienvenido!',
    'login-btn': 'Iniciar sesión',
    'login-into-account': 'Inicie sesión en la cuenta',
    'reset-password-btn': 'Restablecer la contraseña',
    'new-password-btn': 'Guarde la nueva contraseña',
    'validate-phone-enter-code': 'Enter the code received via SMS!'
  },
  'validate-phone-number': {
    'resend-text': '¿No recibiste SMS?',
    'resend-button': 'Reenviar SMS'
  },
  unsubscribe: 'Se anuló su suscripción',
  'unsubscribe-text':
    'Lamentamos mucho que te vayas, pero ahora has cancelado tu suscripción a nuestra lista de correo electrónico.',
  'or-picked-up-at': 'o recogido en',

  packs: 'Embalaje',
  footer: {
    'our-location': 'NUESTRA UBICACIÓN',
    'our-locations': 'NUESTRAS UBICACIONES',
    contact: 'CONTACTO',
    'restaurant-open-hours': 'PROGRAMA DE RESTAURANTE',
    'online-open-hours': 'PROGRAMA COMENZI ONLINE'
  }
}
