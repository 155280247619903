import { defineStore } from 'pinia'
import { Cookies } from 'quasar'

export const useCartStore = defineStore('cart', {
  state: () => ({
    store_id: null,
    name: '',
    phone: '',
    phone_country: null,
    address_id: null,
    address: '',
    address_line2: '',
    use_credit_card_id: null,
    geolocation: {
      lat: null,
      lng: null
    },
    transport_price: 0,
    products: [],
    informations: '',
    accept_promotion: false,
    tos: false,
    contactless_delivery: false,
    cutlery: false,
    payment_method: null,
    voucher: null,
    delivery_type: 'pickup',
    pickup_type: null,
    only_for_later: false,
    for_later: false,
    later_day: null,
    later_time: null,
    loyalty_points: 0
  }),
  actions: {
    updateStoreId(storeId) {
      this.store_id = storeId
    },
    updateDeliveryType(deliveryType) {
      this.delivery_type = deliveryType
    },
    addProduct(product) {
      this.products.push(product)
      this.updateVoucher()
    },
    updateVoucher() {
      let categories = null
      if (this.voucher !== null) {
        if (this.voucher.value_type === 'percent') {
          let total = 0
          let voucherValue = 0
          this.products.forEach(product => {
            if (Array.isArray(product.categories)) {
              categories = product.categories.map(item => item.id)
            } else {
              categories = []
            }
            if (
              (this.voucher.exclude_category_id === null ||
                categories.indexOf(this.voucher.exclude_category_id) === -1) &&
              (this.voucher.category_id === null || categories.indexOf(this.voucher.category_id) !== -1)
            ) {
              total += Number(product.quantity) * Number(product.price)
              if (product.pack_price !== null) {
                total += Number(product.quantity) * Number(product.pack_price)
              }
              product.options.forEach(option => {
                total += Number(product.quantity) * Number(option.price) * Number(option.quantity)
              })
            }
          })
          voucherValue = (this.voucher.value / 100) * total
          this.voucher.value_currency = Math.round(voucherValue * 100) / 100
        } else if (this.voucher.value_type === 'value') {
          this.voucher.value_currency = this.voucher.value
        } else if (this.voucher.value_type === 'products') {
          let voucherValue = 0
          let quantity = 0
          let available = this.voucher.no_of_free_products - this.voucher.already_used_products
          this.products.forEach(product => {
            if (available > 0 && this.voucher.free_products.indexOf(product.id) !== -1) {
              quantity = available >= product.quantity ? product.quantity : available
              voucherValue += quantity * product.price
              if (product.pack_price !== null) {
                voucherValue += quantity * product.pack_price
              }
              available -= quantity
            }
          })
          this.voucher.value_currency = voucherValue
        }
        if (this.voucher.value_currency === 0) {
          this.voucher = null
        }
        if (this.voucher.value_currency === 0) {
          this.voucher = null
        }
      }
    },
    removeProduct(index) {
      this.products.splice(index, 1)
      if (this.products.length === 0) {
        this.updateOnlyForLater(false)
        this.saveVoucher(null)
      }
      this.updateVoucher()
    },
    clearOrder() {
      this.products = []
      this.informations = ''
      this.accept_promotion = false
      this.contactless_delivery = false
      this.cutlery = false
      this.tos = false
      this.voucher = null
      this.transport_price = 0
      this.pickup_type = null
      this.only_for_later = false
      this.loyalty_points = 0
    },
    clearContactInformations() {
      this.name = null
      this.phone = null
    },
    saveAddressAndGeolocation(data) {
      this.address = data.address
      this.geolocation = data.geolocation
      this.delivery_type = data.delivery_type
      LocalStorage.set('be_cart', this.$state)
    },
    saveNamePhone(data) {
      this.name = data.name
      this.phone = data.phone
      this.phone_country = data.phone_country
    },
    saveDeliveryType(data) {
      this.delivery_type = data.delivery_type
    },
    saveVoucher(data) {
      this.voucher = data
      this.updateVoucher()
    },
    removeVoucher() {
      this.voucher = null
      this.updateVoucher()
    },
    setPhoneCountry(data) {
      this.phone_country = data
    },
    setLoyaltyPoints(data) {
      this.loyalty_points = data
    },
    updateOnlyForLater(data) {
      this.only_for_later = data
    },
    updateQuantity(data) {
      this.products[data.productIndex].quantity += data.quantity
      this.updateVoucher()
    }
  },
  persist: true
})
