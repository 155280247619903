const routes = [
  {
    path: '/',
    component: () => import('../layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'homepage',
        component: () => import('../pages/ProductList.vue')
      },
      {
        path: '/account',
        name: 'account',
        component: () => import('../layouts/Account.vue'),
        children: [
          { path: '', component: () => import('../pages/Account/Details.vue'), name: 'account.details' },
          { path: 'orders', component: () => import('../pages/Account/Orders.vue'), name: 'account.orders' },
          {
            path: 'benefits',
            component: () => import('../pages/Account/Benefits.vue'),
            name: 'account.bonus-points'
          }
        ],
        meta: { requiresAuth: true }
      },
      { path: 'reservations', component: () => import('../pages/Reservations.vue'), name: 'reservations' },
      { path: 'events', component: () => import('../pages/Events.vue'), name: 'events' },
      { path: 'event/:url', component: () => import('../pages/EventPage.vue'), name: 'events.page' },
      { path: 'legal/:type', component: () => import('../pages/Legal.vue'), name: 'legal' },
      { path: 'contact', component: () => import('../pages/Contact.vue'), name: 'contact' },
      { path: 'contact/:storeUrl', component: () => import('../pages/ContactStore.vue'), name: 'contact.store' },
      { path: 'unsubscribe', component: () => import('../pages/Unsubscribe.vue'), name: 'unsubscribe' },
      {
        path: 'order-success',
        component: () => import('../pages/Cart/OrderSuccess.vue'),
        name: 'page-order-success.title'
      },
      {
        path: 'page/:type',
        name: 'static-page',
        component: () => import('../pages/PageStatic.vue')
      },
      {
        path: ':menuName/:menuId',
        name: 'menu-page',
        component: () => import('../pages/ProductList.vue'),
        children: [
          {
            name: 'category-page2',
            path: ':categoryName/:categoryId',
            component: () => import('../pages/ProductList.vue')
          }
        ]
      },
      {
        name: 'category-page',
        path: ':categoryName/:categoryId/:menuId',
        component: () => import('../pages/ProductList.vue')
      },
      { path: '/test', component: () => import('../pages/Test.vue') }
    ]
  },
  {
    path: '/integrations',
    component: () => import('../layouts/Cart.vue'),
    children: [
      {
        path: 'braintree/:orderId',
        component: () => import('../pages/Integration/Braintree.vue'),
        name: 'buttons.pay-with-cc'
      },
      {
        path: 'stripe/:orderId',
        component: () => import('../pages/Integration/Stripe.vue'),
        name: 'buttons.pay-with-cc'
      },
      {
        path: 'paypal/:orderId',
        component: () => import('../pages/Integration/PayPal.vue'),
        name: 'payment-methods.paypal'
      },
      {
        path: 'paypal-plus/:orderId',
        component: () => import('../pages/Integration/PayPalPlus.vue'),
        name: 'ppayment-methods.paypal'
      },
      {
        path: 'kiosk-credit-card',
        component: () => import('../pages/Integration/KioskCreditCard.vue'),
        name: 'buttons.pay-with-cc'
      },
      {
        path: 'kiosk-success',
        component: () => import('../pages/Integration/KioskSuccess.vue'),
        name: 'paymentStatus.succeeded'
      }
    ]
  },
  {
    path: '/cart',
    component: () => import('../layouts/Cart.vue'),
    children: [
      { path: '', component: () => import('../components/Cart.vue'), name: 'cart-page' },
      { path: 'checkout', component: () => import('../pages/Cart/Checkout.vue'), name: 'checkout' }
    ]
  },
  {
    path: '/auth',
    component: () => import('../layouts/Authentication.vue'),
    children: [
      { path: 'login', component: () => import('../pages/Authentication/Login.vue'), name: 'auth.login-btn' },
      { path: 'register', component: () => import('../pages/Authentication/Register.vue'), name: 'auth.register' },
      {
        path: 'new-password/:id',
        component: () => import('../pages/Authentication/NewPassword.vue'),
        name: 'auth.new-password'
      },
      {
        path: 'validate-phone-number',
        component: () => import('../pages/Authentication/ValidatePhoneNumber.vue'),
        name: 'auth.validate-phone-number'
      }
    ]
  },
  {
    path: '/500',
    component: () => import('../pages/Error/Error500.vue')
  },
  {
    path: '/:catchAll(.*)*',
    component: () => import('../pages/Error/Error404.vue')
  }
]

export default routes
