import { defineStore } from 'pinia'
import { axiosInstance } from 'boot/axios'
import classifyPoint from 'robust-point-in-polygon'

export const useSettingsStore = defineStore('settings', {
  state: () => ({
    website: {
      id: 0,
      name: '',
      background: null,
      logo: null,
      maps_api: null,
      phone: '',
      email: '',
      payment_card: null,
      color: null,
      color_filter: null
    },
    localization: {
      currency: '',
      country_code: null,
      time_zone: 'GMT',
      delivery_areas: [],
      language: null
    },
    delivery_time: 0,
    pickup_time: 0,
    pages: [],
    stores: [],
    shop_status: {
      delivery_now: false,
      pickup_now: false,
      delivery_later: false,
      pickup_later: false,
      has_delivery: false,
      has_pickup: false,
      someone_has_delivery: false,
      someone_has_pickup: false,
      message: null,
      only_later_hours: [],
      only_later_hours_pickup: []
    },
    delivery_area: {
      transport_price: 0,
      min_order_value: 0,
      min_order_free_transport: 0
    },
    trackers: [],
    external_resources: [],
    company: {
      name: ''
    },
    has_banners: false,
    loyalty: {
      active: false
    },
    show_pages: {
      about_us: true,
      events: true,
      reservations: true
    },
    banners: [],
    meta_tags: [],
    assets_url: null,
    accept_webp: false,
    current_domain: ''
  }),
  actions: {
    fetch(info) {
      return axiosInstance
        .get(`public/website-settings?device=${info.device}`)
        .then(({ data }) => {
          this.website = data.website
          this.localization = data.localization
          this.pages = data.pages
          this.shop_status = data.shop_status
          this.trackers = data.trackers
          this.external_resources = data.external_resources
          this.company = data.company
          this.stores = data.stores
          this.has_banners = data.has_banners
          this.banners = data.banners
          this.delivery_time = data.delivery_time
          this.pickup_time = data.pickup_time
          this.meta_tags = data.meta_tags
          this.show_pages.events = data.show_pages.events
          this.show_pages.about_us = data.show_pages.about_us
          this.show_pages.reservations = data.show_pages.reservations
          if (data.loyalty !== null) {
            this.loyalty = data.loyalty
            this.loyalty.active = data.loyalty.active === 1
          }
          this.assets_url = data.assets_url
          return { menus: data.menus, categories: data.categories }
        })
        .catch(err => {
          if (process.env.NODE_ENV === 'development') {
            console.log(err)
          }
          return { menus: [], categories: [] }
        })
    },
    setAcceptWebp(data) {
      this.accept_webp = data.result
    },
    setCurrentDomain(data) {
      this.current_domain = data
    },
    saveDeliveryArea(area) {
      this.delivery_area = {
        transport_price: Number(area.transport_price),
        min_order_value: Number(area.min_order_value),
        min_order_free_transport: Number(area.min_order_free_transport)
      }
    },
    setDeliveryArea(geolocation) {
      let found = false
      if (this.localization !== null) {
        this.localization.delivery_areas.forEach(area => {
          if (found === false) {
            const polygon = []
            const polygonArea = JSON.parse(area.polygon)
            if (Array.isArray(polygonArea[0])) {
              polygonArea[0].forEach(position => {
                polygon.push([position.lat, position.lng])
              })
            }
            if (classifyPoint(polygon, [geolocation.lat, geolocation.lng]) !== 1) {
              found = true
              this.saveDeliveryArea(area)
            }
          }
        })
      }
    }
  }
})
