<template>
  <router-view />
</template>

<script>
import { Cookies, Platform, setCssVar } from 'quasar'
import { i18n } from 'src/boot/i18n'
import { mapState, mapActions } from 'pinia'
import { useSettingsStore } from 'src/stores/settings'
import { useMenusStore } from 'src/stores/menus'
import { useCategoriesStore } from 'src/stores/categories'
import { useCartStore } from 'src/stores/cart'

const myIcons = {
  'app:menu-bars': 'img:https://assets.boosteat.com/shop-images/be-icons/menu-bars.svg',
  'app:about': 'img:https://assets.boosteat.com/shop-images/be-icons/about.svg',
  'app:account': 'img:https://assets.boosteat.com/shop-images/be-icons/account.svg',
  'app:add': 'img:https://assets.boosteat.com/shop-images/be-icons/add.svg',
  'app:back': 'img:https://assets.boosteat.com/shop-images/be-icons/back.svg',
  'app:bag-arrow': 'img:https://assets.boosteat.com/shop-images/be-icons/bag-arrow.svg',
  'app:bag-cart-green': 'img:https://assets.boosteat.com/shop-images/be-icons/bag-cart-green.svg',
  'app:cart': 'img:https://assets.boosteat.com/shop-images/be-icons/cart.svg',
  'app:cart-black': 'img:https://assets.boosteat.com/shop-images/be-icons/cart-black.svg',
  'app:choose-drink': 'img:https://assets.boosteat.com/shop-images/be-icons/choose-drink.svg',
  'app:choose-sauce': 'img:https://assets.boosteat.com/shop-images/be-icons/choose-sauce.svg',
  'app:close': 'img:https://assets.boosteat.com/shop-images/be-icons/close.svg',
  'app:close-circle': 'img:https://assets.boosteat.com/shop-images/be-icons/close-circle.svg',
  'app:account-details': 'img:https://assets.boosteat.com/shop-images/be-icons/account-details.svg',
  'app:change-password': 'img:https://assets.boosteat.com/shop-images/be-icons/change-password.svg',
  'app:change-password-black': 'img:https://assets.boosteat.com/shop-images/be-icons/change-password-black.svg',
  'app:contact': 'img:https://assets.boosteat.com/shop-images/be-icons/contact.svg',
  'app:contact-data': 'img:https://assets.boosteat.com/shop-images/be-icons/contact-data.svg',
  'app:delete': 'img:https://assets.boosteat.com/shop-images/be-icons/delete.svg',
  'app:delivery': 'img:https://assets.boosteat.com/shop-images/be-icons/delivery.svg',
  'app:discount': 'img:https://assets.boosteat.com/shop-images/be-icons/discount.svg',
  'app:discounts': 'img:https://assets.boosteat.com/shop-images/be-icons/discounts.svg',
  'app:drop-down': 'img:https://assets.boosteat.com/shop-images/be-icons/drop-down.svg',
  'app:events': 'img:https://assets.boosteat.com/shop-images/be-icons/events.svg',
  'app:filter-icon': 'img:https://assets.boosteat.com/shop-images/be-icons/filter-icon.svg',
  'app:location': 'img:https://assets.boosteat.com/shop-images/be-icons/location.svg',
  'app:log-out': 'img:https://assets.boosteat.com/shop-images/be-icons/log-out.svg',
  'app:loyalty': 'img:https://assets.boosteat.com/shop-images/be-icons/loyalty.svg',
  'app:menu': 'img:https://assets.boosteat.com/shop-images/be-icons/menu.svg',
  'app:unlock': 'img:https://assets.boosteat.com/shop-images/be-icons/unlock.svg',
  'app:navigation': 'img:https://assets.boosteat.com/shop-images/be-icons/navigation.svg',
  'app:navigation-button': 'img:https://assets.boosteat.com/shop-images/be-icons/navigation-button.svg',
  'app:no-accepting-orders': 'img:https://assets.boosteat.com/shop-images/be-icons/no-accepting-orders.svg',
  'app:note': 'img:https://assets.boosteat.com/shop-images/be-icons/note.svg',
  'app:orders-bag': 'img:https://assets.boosteat.com/shop-images/be-icons/orders-bag.svg',
  'app:payments-method': 'img:https://assets.boosteat.com/shop-images/be-icons/payments-method.svg',
  'app:pick-up': 'img:https://assets.boosteat.com/shop-images/be-icons/pick-up.svg',
  'app:reservations': 'img:https://assets.boosteat.com/shop-images/be-icons/reservations.svg',
  'app:search': 'img:https://assets.boosteat.com/shop-images/be-icons/search.svg',
  'app:see-password': 'img:https://assets.boosteat.com/shop-images/be-icons/see-password.svg',
  'app:select': 'img:https://assets.boosteat.com/shop-images/be-icons/select.svg',
  'app:setting-account': 'img:https://assets.boosteat.com/shop-images/be-icons/setting-account.svg',
  'app:share': 'img:https://assets.boosteat.com/shop-images/be-icons/share.svg',
  'app:shop-closed': 'img:https://assets.boosteat.com/shop-images/be-icons/shop-closed.svg',
  'app:ticket': 'img:https://assets.boosteat.com/shop-images/be-icons/ticket.svg',
  'app:unsee-paswword': 'img:https://assets.boosteat.com/shop-images/be-icons/unsee-paswword.svg',
  'app:warning': 'img:https://assets.boosteat.com/shop-images/be-icons/warning.svg',
  'app:fast-food': 'img:https://assets.boosteat.com/icons/categories/fast-food.svg',
  'app:allergen-4': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/eg.svg',
  'app:allergen-5': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/fish.svg',
  'app:allergen-1': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/gluten.svg',
  'app:allergen-2': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/lactates.svg',
  'app:allergen-14': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/molluscs.svg',
  'app:allergen-10': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/mustard.svg',
  'app:allergen-6': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/nuts-peanuts.svg',
  'app:allergen-11': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
  'app:allergen-8': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
  'app:allergen-13': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/seeds.svg',
  'app:allergen-3': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/shellfish.svg',
  'app:allergen-7': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/soya.svg',
  'app:allergen-9': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/celery.svg',
  'app:allergen-12': 'img:https://assets.boosteat.com/shop-images/be-icons/allergens/sulfur-dioxide.svg',
  'app:white-dot': 'img:https://assets.boosteat.com/shop-images/be-icons/white-dot.svg',
  'app:green-dot': 'img:https://assets.boosteat.com/shop-images/be-icons/green-dot.svg',
  'app:minus': 'img:https://assets.boosteat.com/shop-images/be-icons/minus.svg',
  'app:plus': 'img:https://assets.boosteat.com/shop-images/be-icons/plus.svg',
  'app:cutlery': 'img:https://assets.boosteat.com/shop-images/be-icons/cutlery.svg',
  'app:logout': 'img:https://assets.boosteat.com/shop-images/be-icons/logout.svg',
  'app:repeat': 'img:https://assets.boosteat.com/shop-images/be-icons/repeat.svg',
  'app:bonus-points': 'img:https://assets.boosteat.com/shop-images/be-icons/bonus-points.svg',
  'app:social-icon-apple': 'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-apple.svg',
  'app:social-icon-facebook': 'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-facebook.svg',
  'app:social-icon-facebook-black':
    'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-facebook-black.svg',
  'app:social-icon-google': 'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-google.svg',
  'app:social-icon-google-black':
    'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-google-black.svg',
  'app:social-icon-instagram-black':
    'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-instagram-black.svg',
  'app:social-icon-tiktok-black':
    'img:https://assets.boosteat.com/shop-images/be-icons/social/social-icon-tiktok-black.svg',
  'app:checkbox-checked': 'img:https://assets.boosteat.com/shop-images/be-icons/checkbox_checked.svg',
  'app:checkbox-unchecked': 'img:https://assets.boosteat.com/shop-images/be-icons/checkbox_unchecked.svg',
  'app:radio-checked': 'img:https://assets.boosteat.com/shop-images/be-icons/radio_checked.svg',
  'app:radio-unchecked': 'img:https://assets.boosteat.com/shop-images/be-icons/radio_unchecked.svg',
  'app:circle-checkmark': 'img:https://assets.boosteat.com/shop-images/be-icons/circle-checkmark.svg',
  'app:apple-pay': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/apple-pay.svg',
  'app:apple-pay-black': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/apple-pay-black.svg',
  'app:google-pay': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/google-pay.svg',
  'app:google-pay-black': 'img:https://assets.boosteat.com/shop-images/be-icons/payments/google-pay-black.svg'
}
export default {
  name: 'App',
  preFetch({ store, ssrContext, redirect, currentRoute }) {
    const storeSettings = useSettingsStore(store)
    return new Promise(resolve => {
      const cookies = process.env.SERVER ? Cookies.parseSSR(ssrContext) : Cookies
      let platform = null
      if (process.env.SERVER) {
        if (typeof ssrContext.req !== 'undefined') {
          storeSettings.setCurrentDomain(ssrContext.req.hostname)
          if (
            typeof ssrContext.req.headers.accept !== 'undefined' &&
            ssrContext.req.headers.accept.indexOf('image/webp') !== -1
          ) {
            storeSettings.setAcceptWebp({ result: true })
          }
        }
        platform = Platform.parseSSR(ssrContext)
      } else {
        platform = Platform
      }
      const storeMenus = useMenusStore(store)
      const storeCategories = useCategoriesStore(store)
      storeSettings
        .fetch({
          device: platform.is.mobile ? 'mobile' : 'desktop'
        })
        .then(result => {
          // check if domain exists
          if (currentRoute.path !== '/404' && storeSettings.website.id === 0) {
            redirect({ path: '/404' })
          }

          // set language
          i18n.global.locale = storeSettings.localization.language
          cookies.set('be_lang', storeSettings.localization.language, {
            expires: 365,
            path: '/',
            sameSite: 'Strict',
            secure: true
          })

          // set default store_id and delivery_type
          const cartStore = useCartStore(store)
          if (storeSettings.stores.length > 0 && cartStore.store_id === null) {
            cartStore.$patch({
              store_id: storeSettings.stores[0].id
            })
          }

          // set menu and categories
          storeMenus.setItems(result.menus)
          storeCategories.setItems(result.categories)
          const selectedMenu = storeMenus.items[0]
          storeMenus.setSelected(selectedMenu)
          if (typeof selectedMenu !== 'undefined') {
            storeCategories.fetch(selectedMenu.id).then(() => {
              if (storeCategories.items.length > 0) {
                const selectedCategory = storeCategories.items[0]
                storeCategories.setSelected(selectedCategory)
                resolve()
              } else {
                resolve()
              }
            })
          } else {
            resolve()
          }
        })
    })
  },
  computed: {
    ...mapState(useCartStore, {
      geolocation: 'geolocation'
    }),
    ...mapState(useSettingsStore, {
      websiteColor: store => store.website.color,
      websiteColorFilter: store => store.website.color_filter
    })
  },
  created() {
    this.$q.iconMapFn = iconName => {
      const icon = myIcons[iconName]
      if (icon !== void 0) {
        return { icon: icon }
      }
    }
    this.$q.iconSet.field.error = 'app:warning'
    this.$q.iconSet.field.clear = 'app:close'
    this.$q.iconSet.tabs.left = 'app:back'
    this.$q.iconSet.tabs.right = 'app:select'
    this.$q.iconSet.chevron.left = 'app:back'
    this.$q.iconSet.chevron.right = 'app:select'
    this.$q.iconSet.carousel.left = 'app:back'
    this.$q.iconSet.carousel.right = 'app:select'
    this.$q.iconSet.arrow.dropdown = 'app:drop-down'
    this.$q.iconSet.expansionItem.icon = 'app:drop-down'
  },
  mounted() {
    if (this.geolocation.lat !== null) {
      this.setDeliveryArea(this.geolocation)
    }
    if (this.websiteColor !== null) {
      setCssVar('accent', this.websiteColor)
      if (this.websiteColorFilter !== null) {
        document.body.style.setProperty('--accent-filter', this.websiteColorFilter)
      }
    }
  },
  methods: {
    ...mapActions(useSettingsStore, ['setDeliveryArea'])
  }
}
</script>
